import React, { useState } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import PageLayout from 'components/layouts/page/page.layout';
import PageSection from 'ui-kit/page-section/page-section';
import Button from 'ui-kit/button/button';
import Link from 'ui-kit/link/link';
import { DemoSliderAlt } from 'components/demo-slider/demo-slider.component';
import BackgroundImage from 'ui-kit/background-image/background-image';
import { Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import '../../../../styles/demo.style.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const OtcDetail = () => {
    const [dropTitle, setDropTitle] = useState('Quantity: 1');
    const imageData = useStaticQuery(graphql`
        query {
            medicine: file(relativePath: { eq: "assets/images/slider1.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    return (
        <PageLayout>
            <PageSection>
                <Row>
                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/secure/over-the-counter" label="Over-the-Counter" />
                                </li>
                                <li className="breadcrumb-item" aria-current="page">
                                    <Link to="/secure/over-the-counter/detail" label="Antacids and acid reducers" />
                                </li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
            </PageSection>
            <PageSection>
                <Row>
                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                        <div className="detail">
                            <div className="detail__left">
                                <div className="detail__number">Item #7893</div>
                                <h1 className="detail__title">Calcium carbonate 500 mg - antacid chewable tablet</h1>
                                <p className="detail__text">
                                    This medication is used to treat low blood calcium levels in people who do not get
                                    enough calcium from their diets.
                                </p>
                                <div className="detail__price">$4.00</div>
                                <hr />

                                <p className="detail__details">
                                    Compared to <a className="blue">Tums&reg;</a>
                                    <br />
                                    Minimum package size: 96ct
                                    <br />
                                    Category: <a className="blue">Antacids and acid reducers</a>
                                    <br />
                                    Availability: In Stock
                                    <a
                                        className="blue"
                                        onClick={() => {
                                            navigate('/secure/over-the-counter');
                                        }}
                                    >
                                        <br />
                                        Back to Search
                                    </a>
                                </p>
                            </div>
                            <div className="detail__right">
                                <BackgroundImage
                                    image={imageData.medicine}
                                    useRoundedCorners={true}
                                    className="detail__image"
                                />
                                <div className="detail__ctas">
                                    <DropdownButton
                                        id="dropdown"
                                        title={dropTitle}
                                        variant="outline-silver"
                                        onSelect={(value) => setDropTitle('Quantity: ' + value)}
                                    >
                                        <Dropdown.Item eventKey="1">1</Dropdown.Item>
                                        <Dropdown.Item eventKey="2">2</Dropdown.Item>
                                        <Dropdown.Item eventKey="3">3</Dropdown.Item>
                                    </DropdownButton>
                                    <Button
                                        className="detail__atc"
                                        label="Add to Cart"
                                        variant="primary"
                                        type="button"
                                        onClick={() => {}}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </PageSection>
            <div className="recs">
                <PageSection>
                    <Row>
                        <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                            <h2 className="recs__title with-line">Similar items to consider</h2>
                            <p className="recs__text">
                                We’d recommend the following over-the-counter products within the{' '}
                                <span className="blue">antacids and acid reducers</span> category.
                            </p>
                            <div className="recs__slider">
                                <DemoSliderAlt />
                            </div>
                        </Col>
                    </Row>
                </PageSection>
            </div>
            <PageSection>
                <div className="blue-links container-fluid">
                    <div className="blue-links-inner">
                        <Row>
                            <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                <h2>Browse by Category</h2>
                                <Row>
                                    <Col md={12} lg={6}>
                                        <ul>
                                            <li>allergy</li>
                                            <li>antacids and acid reducers</li>
                                            <li>anti-diarrheal, laxatives and digestive health</li>
                                            <li>cold, cough and flu</li>
                                            <li>dental and denture care</li>
                                            <li>diabetes management</li>
                                            <li>eye and ear care</li>
                                            <li>health care supplies</li>
                                        </ul>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <ul>
                                            <li>hemorrhoidal preparations</li>
                                            <li>motion sickness</li>
                                            <li>pain relievers</li>
                                            <li>supports and braces</li>
                                            <li>topical skin care</li>
                                            <li>vitamins and minerals</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </PageSection>
        </PageLayout>
    );
};

export default OtcDetail;
